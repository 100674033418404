import { type BookSettings, type Prefs, type BookMeta } from '../types/book';
import { checkIfFullscreen, getTheme, getScaleFactor, getAllTextSelectors, findMostCommonTextSelector } from './books';
import { ensureContrast } from './color';
import { defaultBookSettings } from './defaults';
export const checkAndAdjustStyles = async (
  doc: Document | undefined,
  settings: BookSettings,
  prefs: Prefs,
  bookMeta: BookMeta,
  ignoreClass?: string
): Promise<Record<string, any>> => {
  if (!doc) {
    console.warn('No document to adjust styles for');
    return {};
  }

  const textSelectors = getAllTextSelectors(doc, ignoreClass);
  const mostCommonSelector = findMostCommonTextSelector(doc, textSelectors);
  const adjustedStyles: Record<string, Record<string, any>> = {};

  const commonElement = doc.querySelector(mostCommonSelector) as Element;
  const scaleFactor =
    (commonElement &&
      getScaleFactor(
        commonElement,
        !settings.fontSize || settings.fontSize === defaultBookSettings.fontSize ? 16 : settings.fontSize
      )) ||
    1;
  console.debug('most common selector=', mostCommonSelector, 'scaleFactor=', scaleFactor);
  const baselineMargin =
    parseFloat(window.getComputedStyle(commonElement).marginLeft) +
    parseFloat(window.getComputedStyle(commonElement).paddingLeft);
  let marginAdjustment = 0;
  // if (settings.margin !== undefined && settings.margin !== defaultBookSettings.margin) {
  //   marginAdjustment = Math.round(100 * (settings.margin * 4 - baselineMargin)) / 100;
  // }
  // console.debug('marginAdjustment', marginAdjustment);

  // Adjust styles for the most common selector
  adjustedStyles[mostCommonSelector] = await adjustStylesForSelector(
    doc,
    settings,
    prefs,
    mostCommonSelector,
    bookMeta,
    scaleFactor,
    marginAdjustment
  );

  // Adjust styles for other selectors
  for (const selector of textSelectors) {
    if (selector !== mostCommonSelector) {
      adjustedStyles[selector] = await adjustStylesForSelector(
        doc,
        settings,
        prefs,
        selector,
        bookMeta,
        scaleFactor,
        marginAdjustment,
        mostCommonSelector
      );
    }
  }

  return adjustedStyles;
};

/**
 * Checks if an image is likely to be a full-page or large standalone image.
 * @param element The element to check
 * @param doc The document containing the element
 * @returns True if the element is likely a full-page or large image, false otherwise
 */
const isLargeStandaloneImage = (element: Element, doc: Document): boolean => {
  return false;
  //   const img = element.tagName.toLowerCase() === 'img' ? (element as HTMLImageElement) : element.querySelector('img');

  //   if (!img) return false;

  //   // Get the viewport dimensions
  //   const viewportWidth = doc.documentElement.clientWidth;
  //   const viewportHeight = doc.documentElement.clientHeight;

  //   // Check if the image is large relative to the viewport
  //   const isLarge = img.naturalWidth > viewportWidth * 0.5 || img.naturalHeight > viewportHeight * 0.5;

  //   // Check if the image is standalone (not mixed with text)
  //   const parent = img.parentElement;
  //   const isStandalone = !!(
  //     parent &&
  //     parent.childNodes.length <= 3 && // Allow for potential wrapper elements
  //     !parent.textContent?.trim()
  //   );

  //   if (isLarge) {
  //     console.debug('isLargeStandaloneImage because of image size', element);
  //   }

  //   return isLarge;
  // TODO: need standalone check?
};

const containsText = (element: Element): boolean => {
  return element.textContent?.trim() !== '';
};

/**
 * Determines if an element is displayed as block or inline.
 * @param element The element to check
 * @returns 'block' if the element is displayed as block, 'inline' otherwise
 */
const isBlockElement = (element: Element): boolean => {
  const computedStyle = window.getComputedStyle(element);
  const display = computedStyle.display;

  // Check for block-level display values
  if (['block', 'flex', 'grid', 'table', 'list-item'].includes(display)) {
    return true;
  }

  // All other display values are considered inline for this purpose
  return false;
};

const adjustStylesForSelector = async (
  doc: Document,
  settings: BookSettings,
  prefs: Prefs,
  selector: string,
  bookMeta: BookMeta,
  scaleFactor: number,
  marginAdjustment: number,
  commonSelector?: string
): Promise<Record<string, any>> => {
  const adjustedStyle: Record<string, any> = {};
  const element = doc.querySelector(selector) as Element;
  if (!element) return {};
  const commonElement = commonSelector ? (doc.querySelector(commonSelector) as Element) : null;

  // Helper function to check if a property should be adjusted
  const shouldAdjustProperty = (property: string): boolean => {
    if (!commonSelector) return true;
    const elementStyle = getComputedStyle(element)[property as any];
    const commonStyle = getComputedStyle(commonElement as Element)[property as any];
    return elementStyle === commonStyle;
  };

  // Check if the element is a large standalone image
  if (isLargeStandaloneImage(element, doc)) {
    console.debug('making image full page', element);
    console.debug(getComputedStyle(element).height);
    console.debug(getComputedStyle(doc.body).paddingTop);
    console.debug(window.innerHeight);
    console.debug(window.outerHeight);

    const maxHeight = `${
      Math.max(parseFloat(getComputedStyle(element).height), window.outerHeight) -
      parseFloat(getComputedStyle(doc.body).paddingTop) -
      parseFloat(getComputedStyle(doc.body).paddingBottom)
    }px`;
    // if (height < window.innerHeight) {
    //   adjustedStyle.maxHeight = `${height}px`;
    // }
    return {
      margin: '0 auto',
      padding: '0',
      textIndent: '0',
      maxWidth: '100%',
      maxHeight: maxHeight,
      objectFit: 'cover',
      //   display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: getComputedStyle(element).height,
    };
  }

  // Check and adjust font size
  if (
    commonSelector &&
    settings.fontSize &&
    settings.fontSize !== defaultBookSettings.fontSize &&
    containsText(element)
  ) {
    //} && shouldAdjustProperty('fontSize')) {
    adjustedStyle.fontSize = adjustFontSizeProportionally(
      doc,
      settings.fontSize,
      commonSelector || selector,
      selector,
      bookMeta,
      scaleFactor
    );
  }

  // Check and adjust line spacing
  if (
    settings.lineSpacing &&
    settings.lineSpacing !== defaultBookSettings.lineSpacing &&
    shouldAdjustProperty('lineHeight') &&
    isBlockElement(element) &&
    containsText(element)
  ) {
    adjustedStyle.lineHeight = await checkAndAdjustLineSpacing(doc, settings.lineSpacing, selector);
  }

  // Check and adjust horizontal margins (padding)
  //   if (
  //     settings.margin !== undefined &&
  //     settings.margin !== defaultBookSettings.margin &&
  //     isBlockElement(element) &&
  //     !(element.tagName === 'DIV')

  //     // !element.tagName.startsWith('H')
  //   ) {
  //     // if (shouldAdjustProperty('paddingLeft')) {
  //     const adjustedMarginLeft = await checkAndAdjustMargin(doc, settings.margin, selector, 'left', marginAdjustment);
  //     adjustedStyle.paddingLeft = adjustedMarginLeft;
  //     // }
  //     // if (shouldAdjustProperty('paddingRight')) {
  //     const adjustedMarginRight = await checkAndAdjustMargin(doc, settings.margin, selector, 'right', marginAdjustment);
  //     adjustedStyle.paddingRight = adjustedMarginRight;
  //     // }
  //   }

  // Check and adjust text alignment
  if (
    settings.alignment &&
    shouldAdjustProperty('textAlign') &&
    ['left', 'justify'].includes(settings.alignment) &&
    ['left', 'justify'].includes(getComputedStyle(element).textAlign) &&
    isBlockElement(element) &&
    containsText(element)
  ) {
    adjustedStyle.textAlign = await checkAndAdjustTextAlignment(doc, settings.alignment, selector);
  }

  // Check and adjust font family
  if (
    settings.fontFamily &&
    settings.fontFamily !== 'default' &&
    element.tagName === 'P' &&
    shouldAdjustProperty('fontFamily') &&
    containsText(element)
  ) {
    adjustedStyle.fontFamily = `${settings.fontFamily}, sans-serif`;
  }

  // Check and adjust font weight
  if (
    settings.fontWeight &&
    settings.fontWeight !== 0 &&
    element.tagName === 'P' &&
    shouldAdjustProperty('fontWeight') &&
    containsText(element)
  ) {
    adjustedStyle.fontWeight = `${settings.fontWeight}`;
  }

  // Check and adjust vertical margins
  if (settings.marginY !== undefined && element.tagName === 'P' && settings.marginY !== -0.1) {
    if (shouldAdjustProperty('marginTop') && shouldAdjustProperty('marginBottom')) {
      adjustedStyle.marginTop = `${settings.marginY}em`;
      adjustedStyle.marginBottom = `${settings.marginY}em`;
    }
  }

  // Check and adjust text indent
  if (
    settings.indent !== undefined &&
    element.tagName === 'P' &&
    settings.indent !== -0.25 &&
    shouldAdjustProperty('textIndent') &&
    containsText(element)
  ) {
    adjustedStyle.textIndent = `${settings.indent}em`;
  }

  // Check and adjust text color
  adjustedStyle.color = await checkAndAdjustTextColor(doc, prefs, selector);

  return adjustedStyle;
};

const adjustFontSizeProportionally = (
  doc: Document,
  desiredSize: number,
  commonSelector: string,
  selector: string,
  bookMeta: BookMeta,
  scaleFactor: number
): string => {
  const element = doc.querySelector(selector);
  if (!element) return `${desiredSize}px`;
  const elementComputedStyle = window.getComputedStyle(element);
  const elementComputedSize = parseFloat(elementComputedStyle.fontSize);
  const parentComputedStyle = parseFloat(window.getComputedStyle(element.parentElement || element).fontSize);
  //   const commonElement = doc.querySelector(commonSelector);
  //   if (!commonElement) return `${desiredSize}px`;
  //   const commonComputedSize = parseFloat(window.getComputedStyle(commonElement).fontSize);
  //   if (bookMeta.overallScaleFactor) {
  //     return `${elementComputedSize * bookMeta.overallScaleFactor * scaleFactor}px`;
  //   } else {
  return `${elementComputedSize / parentComputedStyle}em`;
  //   }
};

const checkAndAdjustTextColor = async (doc: Document, prefs: Prefs, selector: string): Promise<string> => {
  const testElement = doc.querySelector(selector);
  if (!testElement) return '';

  const theme = getTheme(prefs.theme);
  const defaultTextColor = theme === 'light' ? '#000' : '#ddd';
  const defaultBackgroundColor = theme === 'light' ? '#fff' : '#000';

  const computedStyle = window.getComputedStyle(testElement);
  const computedColor = computedStyle.color;
  const computedBackgroundColor = computedStyle.backgroundColor;

  // If the background is transparent, use the body's background color
  const effectiveBackgroundColor = defaultBackgroundColor;
  // computedBackgroundColor === 'rgba(0, 0, 0, 0)'
  //   ? window.getComputedStyle(doc.documentElement).backgroundColor
  //   : computedBackgroundColor;

  // Use the computed colors if available, otherwise fall back to theme defaults
  const textColor = computedColor || defaultTextColor;
  const backgroundColor = effectiveBackgroundColor || defaultBackgroundColor;

  return ensureContrast(textColor, backgroundColor, defaultTextColor);
};

const checkAndAdjustLineSpacing = async (doc: Document, desiredSpacing: number, selector: string): Promise<string> => {
  const testElement = doc.querySelector(selector);
  if (!testElement) return `${desiredSpacing}`;

  const computedSpacing = window.getComputedStyle(testElement).lineHeight;
  if (computedSpacing !== `${desiredSpacing}`) {
    return `${desiredSpacing}`;
  }
  return `${desiredSpacing}`;
};

const checkAndAdjustMargin = async (
  doc: Document,
  desiredMargin: number,
  selector: string,
  side: 'left' | 'right',
  marginAdjustment: number
): Promise<string> => {
  const testElement = doc.querySelector(selector);
  if (!testElement) return `${desiredMargin * 4 + marginAdjustment}px`;

  const computedStyle = window.getComputedStyle(testElement);
  const baselineMargin = parseFloat(computedStyle[`margin${side === 'left' ? 'Left' : 'Right'}`]);
  const baselinePadding = parseFloat(computedStyle[`padding${side === 'left' ? 'Left' : 'Right'}`]);
  const baseline = baselineMargin + baselinePadding;

  return `${baseline + marginAdjustment}px`;
};

const checkAndAdjustTextAlignment = async (
  doc: Document,
  desiredAlignment: string,
  selector: string
): Promise<string> => {
  //   const testElement = doc.querySelector(selector);
  //   if (!testElement) return desiredAlignment;

  // check if the text alignment is the same as the desired alignment, iff it's left or justified.
  //   const computedAlignment = window.getComputedStyle(testElement).textAlign;

  return desiredAlignment;
};
