import React, { useContext } from 'react';
import { Rendition } from 'epubjs';
import type { NavItem } from 'epubjs';
import { useNavigate } from 'react-router-dom';
import { useTheme, Drawer, AppBar, Box, Typography, Divider, IconButton, Stack } from '@mui/material';
import {
  Close as CloseIcon,
  Home as HomeIcon,
  ArrowBack as ArrowBackIcon,
  FormatListNumbered as FormatListNumberedIcon,
  Comment as CommentIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import TocComponent from './TocComponent';
import NotesComponent from './NotesComponent';
import SettingsComponent from './SettingsComponent';
import { handleBackNavigation, updateBook } from '../utils/books';
import type {
  AnchorLocations,
  BookMeta,
  BookSettings,
  RichLocation,
  SnackbarMessage,
  UpdateRecord,
} from '../types/book';
import { AppContext } from '../context/AppContext';
import type { EpubView } from './EpubView';

const iconButtonStyle = {
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '12px',
  '& .MuiSvgIcon-root': {
    fontSize: '2rem',
  },
  color: '#f0f0f0',
};

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

interface TemporaryDrawerProps {
  open: boolean;
  onClose: () => void; // Adjust if onClose needs to handle more than just a simple callback
  toc: NavItem[]; // Pass toc as a prop
  setLocation: (location: string) => void; // Pass setLocation as a prop
  // styles: IReactReaderStyle; // Pass styles as a prop
  //   reactReader: ReactReader;
  rendition?: Rendition | undefined;
  book: BookMeta;
  updateSettings: (settings: BookSettings, progress?: number) => void;
  goToPercentage: (percentage: number) => void;
  historyRef: React.MutableRefObject<UpdateRecord | undefined>;
  bookRef: React.MutableRefObject<BookMeta | undefined>;
  setUsedBack: (usedBack: boolean) => void;
  openTab: number;
  setOpenTab: (tab: number) => void;
  setSnackbarMessage: (message: SnackbarMessage) => void;
  anchors?: AnchorLocations;
  chapterLocations?: RichLocation[];
  readerRef: React.RefObject<EpubView>;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`drawer-tabpanel-${index}`}
      aria-labelledby={`drawer-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'} variant={'body2'}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `drawer-tab-${index}`,
    'aria-controls': `drawer-tabpanel-${index}`,
  };
}

export default function TemporaryDrawer({
  open,
  toc,
  onClose,
  setLocation,
  rendition,
  book,
  updateSettings,
  goToPercentage,
  historyRef,
  bookRef,
  setUsedBack,
  openTab,
  setOpenTab,
  setSnackbarMessage,
  anchors,
  readerRef,
  chapterLocations,
}: TemporaryDrawerProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { effectiveTheme } = useContext(AppContext);

  const handleBack = () =>
    handleBackNavigation(
      bookRef.current?.settings.updates || [],
      historyRef.current || [Date.now(), -1],
      setSnackbarMessage,
      goToPercentage,
      setUsedBack,
      historyRef
    );

  const handleHome = async () => {
    const b = bookRef.current || book;
    if (!b.visibleAt) {
      b.visibleAt = {};
    }
    b.visibleAt[Date.now()] = false;
    await updateBook({ ...b, visibleAt: b.visibleAt }, true);
    navigate('/');
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor='top'
      PaperProps={{
        style: {
          top: '50px',
          maxHeight: '75%',
          height: '75%',
          width: '100%',
          maxWidth: '100%',
        },
      }}
      //   sx={{
      //     maxHeight: "75% !important",
      //   }}

      //   PaperProps={{
      // style: {
      //   backgroundColor: "#121212", // Dark theme background color
      //   color: "white", // Text color for dark theme
      // Add other styling as needed
      // },
      //   }}
    >
      <AppBar position='static' color='primary'>
        <Stack direction='row' spacing={0} divider={<Divider orientation='vertical' flexItem />} sx={{ width: '100%' }}>
          <IconButton onClick={handleHome} sx={iconButtonStyle}>
            <HomeIcon />
          </IconButton>

          <IconButton onClick={() => setOpenTab(0)} sx={iconButtonStyle}>
            <SettingsIcon />
          </IconButton>
          <IconButton onClick={() => setOpenTab(1)} sx={iconButtonStyle}>
            <CommentIcon />
          </IconButton>
          <IconButton onClick={() => setOpenTab(2)} sx={iconButtonStyle}>
            <FormatListNumberedIcon />
          </IconButton>
          <IconButton onClick={handleBack} sx={iconButtonStyle}>
            <ArrowBackIcon />
          </IconButton>
          <IconButton onClick={onClose} sx={iconButtonStyle}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </AppBar>
      <TabPanel value={openTab} index={0} dir={theme.direction}>
        <SettingsComponent settings={book.settings} updateSettings={updateSettings} />
      </TabPanel>
      <TabPanel value={openTab} index={1} dir={theme.direction}>
        <NotesComponent
          bookId={book.id}
          setLocation={setLocation}
          closeDrawer={onClose}
          settings={book.settings}
          updateSettings={updateSettings}
        />
      </TabPanel>
      <TabPanel value={openTab} index={2} dir={theme.direction}>
        <TocComponent
          toc={toc}
          setLocation={setLocation}
          anchors={anchors}
          rendition={rendition}
          closeDrawer={onClose}
          book={book}
          goToPercentage={goToPercentage}
          readerRef={readerRef}
          chapterLocations={chapterLocations}
        />
      </TabPanel>
    </Drawer>
  );
}
