/**
 * Parses various color string formats and converts them to RGB values.
 * @param color - The color string (hex, rgb, rgba, or color name)
 * @returns An array of [r, g, b] values (0-255)
 */
function parseColor(color: string): [number, number, number] {
  // Remove whitespace and convert to lowercase
  color = color.toLowerCase().replace(/\s/g, '');

  // Hex format
  if (color.startsWith('#')) {
    return parseHex(color);
  }

  // RGB or RGBA format
  if (color.startsWith('rgb')) {
    return parseRgb(color);
  }

  // Color names
  const namedColors: { [key: string]: [number, number, number] } = {
    black: [0, 0, 0],
    white: [255, 255, 255],
    red: [255, 0, 0],
    green: [0, 128, 0],
    blue: [0, 0, 255],
    // Add more common colors as needed
  };

  if (color in namedColors) {
    return namedColors[color];
  }

  // Default to black if color format is unrecognized
  return [0, 0, 0];
}

function parseHex(hex: string): [number, number, number] {
  hex = hex.replace(/^#/, '');
  if (hex.length === 3) {
    return [parseInt(hex[0] + hex[0], 16), parseInt(hex[1] + hex[1], 16), parseInt(hex[2] + hex[2], 16)];
  }
  const result = /^([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : [0, 0, 0];
}

function parseRgb(rgb: string): [number, number, number] {
  const match = rgb.match(/^rgba?\((\d+),(\d+),(\d+)(?:,\d+(?:\.\d+)?)?\)$/);
  return match ? [parseInt(match[1], 10), parseInt(match[2], 10), parseInt(match[3], 10)] : [0, 0, 0];
}

function rgbToHex(r: number, g: number, b: number): string {
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

function getLuminance(r: number, g: number, b: number): number {
  const a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

function getContrast(rgb1: [number, number, number], rgb2: [number, number, number]): number {
  const lum1 = getLuminance(...rgb1);
  const lum2 = getLuminance(...rgb2);
  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) / (darkest + 0.05);
}

export function ensureContrast(textColor: string, backgroundColor: string, defaultTextColor: string): string {
  const textRgb = parseColor(textColor);
  const bgRgb = parseColor(backgroundColor);
  const defaultTextRgb = parseColor(defaultTextColor);

  const contrast = getContrast(textRgb, bgRgb);

  if (contrast >= 3) {
    return textColor; // Sufficient contrast
  }

  // If contrast is insufficient, use the default text color
  return defaultTextColor;
}
