import React from "react";
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { durationFormat } from "../utils/books";

type Props = {
  data: { label: string; value: number }[];
  dot?: boolean;
  movingAverageWindow?: number;
  isAnimationActive?: boolean;
  animationDuration?: number;
};

const calculateMovingAverage = (
  data: { label: string; value: number }[],
  window: number
) => {
  if (window <= 1) return data.map((d) => ({ ...d }));

  const result = [];
  for (let i = 0; i < data.length; i++) {
    if (i < window - 1) {
      result.push({ label: data[i].label, value: null });
    } else {
      const windowData = data.slice(i - window + 1, i + 1);
      const windowAverage =
        windowData.reduce((sum, d) => sum + d.value, 0) / window;
      result.push({ label: data[i].label, value: Math.ceil(windowAverage) });
    }
  }
  return result;
};

export const ReadingChart: React.FC<Props> = ({
  data,
  dot = true,
  movingAverageWindow,
  isAnimationActive = false,
  animationDuration = 2500,
}) => {
  const movingAverageData = movingAverageWindow
    ? calculateMovingAverage(data, movingAverageWindow)
    : [];

  return (
    <ResponsiveContainer width="100%" height={160}>
      <LineChart
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 30,
          bottom: 10,
        }}
      >
        <XAxis dataKey="label" />
        <YAxis
          //   padding={{ top: 20 }}
          hide={true}
          type="number"
          //   includeHidden
          domain={[0, Math.max(...data.map((d) => d.value))]}
          allowDataOverflow={false}
        />
        <Tooltip
          itemStyle={{ color: "yellow" }}
          separator=""
          contentStyle={{
            color: "white",
            backgroundColor: "rgba(120, 120, 120, 0.8)",
          }}
          labelFormatter={(value) => ""}
          formatter={(value: number) => [`${durationFormat(value)}`, ""]}
        />
        <Line
          //   data={data}
          type="monotone"
          dataKey="value"
          stroke="#8884d8"
          dot={dot}
          isAnimationActive={isAnimationActive}
          animationDuration={animationDuration}
          activeDot={{ r: 8 }}
        />
        {movingAverageWindow && (
          <Line
            type="monotone"
            data={movingAverageData}
            dataKey="value"
            stroke="#82ca9d"
            dot={false}
            isAnimationActive={isAnimationActive}
            animationDuration={animationDuration}
            activeDot={{
              //   stroke: "red",
              //   strokeDasharray: "3 3",
              strokeWidth: 2,
              r: 4,
            }}
            strokeDasharray="3 3"
            tooltipType="none"
          />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ReadingChart;
