import { saveObject, loadObject } from './indexedDB';

/**
 * Inverts grayscale images in the document
 * @param isDarkMode - Whether dark mode is enabled
 * @param bookId - The ID of the book
 * @param urlCache - A cache of URL mappings
 * @param doc - The document to process
 */
const invertGrayscaleImages = async (
  isDarkMode: boolean,
  bookId: string,
  urlCache: Record<string, string>,
  doc: Document
) => {
  // Select elements with background images
  const bgImageSelectors = [
    '[style*="background-image"]',
    'hr',
    // Add more selectors as needed
  ];
  const elementsWithBackgroundImage = doc.querySelectorAll(bgImageSelectors.join(','));
  const images = doc.querySelectorAll('img');
  const svgImages = doc.querySelectorAll('svg image');

  const processElement = async (element: Element, src: string) => {
    if (await isGrayscale(src, bookId, urlCache)) {
      element.classList.add('invert-grayscale');
    }
  };

  for (const element of elementsWithBackgroundImage) {
    const computedStyle = getComputedStyle(element);
    const backgroundImage = computedStyle.backgroundImage;

    if (backgroundImage && backgroundImage !== 'none') {
      const match = backgroundImage.match(/url\(['"]?(.*?)['"]?\)/);
      if (match) {
        const url = match[1];
        await processElement(element, url);
      }
    }
  }

  for (const img of images) {
    await processElement(img, (img as HTMLImageElement).src);
  }

  for (const svgImage of svgImages) {
    const href = svgImage.getAttribute('xlink:href') || svgImage.getAttribute('href');
    if (href) {
      await processElement(svgImage, href);
    }
  }
};

const getCacheKey = (src: string, urlCache: Record<string, string>): string => {
  // Perform reverse lookup
  const originalPath = Object.keys(urlCache).find((key) => urlCache[key] === src);

  if (originalPath) {
    return originalPath;
  }

  console.warn('Unable to find original path for image, using source URL', src);
  return src;
};

const getGrayscaleCache = async (bookId: string): Promise<Record<string, boolean>> => {
  const cacheData = (await loadObject(`grayscale-${bookId}`)) as Record<string, boolean>;
  return cacheData || {};
};

const updateGrayscaleCache = async (bookId: string, newEntries: Record<string, boolean>): Promise<void> => {
  const existingCache = await getGrayscaleCache(bookId);

  // Merge new entries with existing cache
  for (const [key, value] of Object.entries(newEntries)) {
    existingCache[key] = value;
  }

  await saveObject(`grayscale-${bookId}`, existingCache);
};

/**
 * Checks if an image is grayscale
 * @param src - The source URL of the image
 * @param bookId - The ID of the book
 * @param urlCache - A cache of URL mappings
 * @returns A promise that resolves to true if the image is grayscale, false otherwise
 */
const isGrayscale = async (src: string, bookId: string, urlCache: Record<string, string>): Promise<boolean> => {
  const cacheKey = getCacheKey(src, urlCache);
  const cache = await getGrayscaleCache(bookId);

  if (cache[cacheKey] !== undefined) {
    console.debug('cache hit', cacheKey, cache[cacheKey]);
    return cache[cacheKey];
  }

  // Create a new image element
  const img = new Image();
  img.crossOrigin = 'anonymous';
  img.src = src;

  // Wait for the image to load
  await new Promise((resolve, reject) => {
    img.onload = resolve;
    img.onerror = reject;
  });

  // Perform grayscale check
  const canvas = document.createElement('canvas');
  canvas.width = img.naturalWidth;
  canvas.height = img.naturalHeight;

  const ctx = canvas.getContext('2d');
  if (!ctx) return false;
  ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight);

  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const data = imageData.data;

  const pixels = data.length / 4;
  const stepSize = Math.max(1, Math.floor(pixels / 31));
  const tolerance = 16;
  const ratio = 0.75;

  let result = true;
  for (let i = 0; i < data.length; i += 4 * stepSize) {
    if (
      Math.max(data[i], data[i + 1], data[i + 2]) - Math.min(data[i], data[i + 1], data[i + 2]) > tolerance &&
      Math.min(data[i], data[i + 1], data[i + 2]) / Math.max(data[i], data[i + 1], data[i + 2]) < ratio
    ) {
      console.debug('isGrayscale', i, data[i], data[i + 1], data[i + 2], data[i + 3]);
      result = false;
      break;
    }
  }

  // Update cache with new entry
  const newEntries = { [cacheKey]: result };
  console.debug('adding to cache', newEntries);
  await updateGrayscaleCache(bookId, newEntries);

  return result;
};

export { invertGrayscaleImages };
