import { create } from 'zustand';

interface OnlineState {
  isOnline: boolean;
  setOnline: (status: boolean) => void;
}

export const useOnlineStore = create<OnlineState>((set, get) => ({
  isOnline: navigator.onLine,
  setOnline: (status: boolean) => {
    const currentStatus = get().isOnline;
    if (currentStatus !== status) {
      set({ isOnline: status });
    }
  },
}));
