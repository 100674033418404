import { useRef, useCallback } from "react";

/**
 * Creates a throttled version of a function.
 *
 * @template T - The type of the function to be throttled.
 * @param {T} func - The function to throttle.
 * @param {number} delay - The minimum time interval (in milliseconds) between function calls.
 * @returns {T} A throttled version of the input function.
 *
 * @description
 * This function creates a throttled version of the provided function.
 * The throttled function will be called at most once per specified delay,
 * regardless of how many times it is invoked. If called multiple times
 * within the delay period, it will execute once at the end of the delay period.
 *
 * @example
 * const throttledScroll = throttle(() => console.log('Scrolled'), 1000);
 * window.addEventListener('scroll', throttledScroll);
 */
function throttle<T extends (...args: any[]) => any>(func: T, delay: number): T {
  let lastRan = Date.now();
  let timeoutId: NodeJS.Timeout | null = null;

  return ((...args: Parameters<T>) => {
    const now = Date.now();
    if (now - lastRan >= delay) {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
      func(...args);
      lastRan = now;
    } else if (!timeoutId) {
      timeoutId = setTimeout(() => {
        func(...args);
        lastRan = Date.now();
        timeoutId = null;
      }, delay - (now - lastRan));
    }
  }) as T;
}

/**
 * A custom hook that creates a throttled version of a function.
 *
 * Note: Wrapping the function in useCallback ensures that the same function instance
 *       is passed to useThrottle, preventing unnecessary re-creations of the throttled function.
 *
 * @template T - The type of the function to be throttled.
 * @param {T} func - The function to throttle.
 * @param {number} delay - The minimum time interval (in milliseconds) between function calls.
 * @returns {T} A throttled version of the input function.
 *
 * @example
 * const throttledHandleScroll = useThrottle(handleScroll, 200);
 * window.addEventListener('scroll', throttledHandleScroll);
 *
 * @example
 * const handleScroll = useCallback(() => {
 *   console.log('Scroll event');
 * }, []);
 *
 * const throttledHandleScroll = useThrottle(handleScroll, 200);
 * window.addEventListener('scroll', throttledHandleScroll);
 */
function useThrottle<T extends (...args: any[]) => any>(func: T, delay: number): T {
  const lastRan = useRef(Date.now());

  return useCallback(
    (...args: Parameters<T>) => {
      const now = Date.now();
      if (now - lastRan.current >= delay) {
        func(...args);
        lastRan.current = now;
      }
    },
    [func, delay]
  ) as T;
}

/**
 * Creates a debounced version of a function with a cancel method.
 *
 * @template T - The type of the function to be debounced
 * @param {T} func - The function to debounce
 * @param {number} wait - The number of milliseconds to delay
 * @returns A debounced version of the input function with a cancel method
 */
function debounce<T extends (...args: any[]) => any>(func: T, wait: number) {
  let timeoutId: NodeJS.Timeout | null = null;

  const debouncedFunc = function (this: ThisParameterType<T>, ...args: Parameters<T>) {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }

    return new Promise<ReturnType<T>>((resolve) => {
      timeoutId = setTimeout(() => {
        const result = func.apply(this, args);
        if (result instanceof Promise) {
          result.then(resolve);
        } else {
          resolve(result);
        }
      }, wait);
    }) as T extends (...args: any[]) => Promise<infer R> ? Promise<R> : void;
  };

  debouncedFunc.cancel = () => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  };

  return debouncedFunc;
}

function afterLayout(callback: () => void) {
  requestAnimationFrame(() => {
    requestAnimationFrame(() => {
      setTimeout(callback, 0);
    });
  });
}

export { throttle, useThrottle, debounce, afterLayout };
