import React, { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// Extend the props to include all possible img attributes
type MarkdownImageProps = React.ImgHTMLAttributes<HTMLImageElement>;

const MarkdownImage: React.FC<MarkdownImageProps> = ({
  src,
  alt,
  ...props
}) => {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  if (!src) return null; // Handle case where src is undefined

  return (
    <>
      <img
        src={src}
        alt={alt || ""}
        {...props} // Spread any additional props
        style={{
          maxWidth: "100%",
          height: "auto",
          cursor: "pointer",
          display: "block",
          margin: "0 auto",
          ...props.style, // Merge with any style props passed in
        }}
        onClick={(e) => {
          e.stopPropagation();
          setIsLightboxOpen(true);
        }}
      />
      <Lightbox
        open={isLightboxOpen}
        close={() => setIsLightboxOpen(false)}
        slides={[{ src }]}
      />
    </>
  );
};

export default MarkdownImage;
